import { NavBar, Image as VanImage } from 'vant';
import { orderPreview } from '../../../api/order.js';
import BackOff from '../../common/BackOff.vue';
export default {
  name: 'Photobook',
  components: {
    BackOff,
    [NavBar.name]: NavBar,
    [VanImage.name]: VanImage
  },
  data() {
    return {
      orderId: this.$route.query.orderId,
      orderProductId: this.$route.query.orderProductId,
      backUrl: this.$route.query.backUrl,
      // 图片上传后, 用来存储最后提交商品的值
      pictureInfoList: [],
      coverageWidthTitle: '',
      coverageHeightTitle: '',
      coverageWidth: '',
      coverageHeight: '',
      pictureList: []
    };
  },
  created: async function () {
    this.getOrderProductPreview();
    // this.putUserImage();
  },

  methods: {
    getOrderProductPreview() {
      const previewParams = {
        orderId: this.orderId,
        orderProductId: this.orderProductId
      };
      orderPreview(previewParams).then(res => {
        // console.log(res.data)
        this.pictureInfoList = res.data.data.customData.orderProduct.productDetail.detail.customize;
        this.pictureList = this.pictureInfoList;
        this.coverageWidth = '45vw';
        // eslint-disable-next-line no-eval
        this.coverageHeight = eval(45 / this.pictureInfoList[1].coverImgs[0].width * this.pictureInfoList[1].coverImgs[0].height) + 'vw';

        // console.log("===========finish=========")
      });
    }
  }
};