import request from '../util/request'

export function cartSubmit(data) {
  return request({
    url: '/order/cart/submit',
    method: 'post',
    data: data
  })
}

export function orderDetail(orderId) {
  return request({
    url: '/order/detail',
    method: 'get',
    params: orderId
  })
}

export function orderPreview(params) {
  return request({
    url: '/order/preview',
    method: 'get',
    params: params
  })
}

export function orderList() {
  return request({
    url: '/order/list',
    method: 'get'
  })
}

export function orderCancel(data) {
  return request({
    url: 'order/cancel',
    method: 'post',
    data: data
  })
}

export function orderChangeAddress(data) {
  return request({
    url: 'order/change-address',
    method: 'post',
    data: data
  })
}
